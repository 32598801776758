.attended {
  font-weight: bold !important;
  &.Present {
    color: #097969;
  }
  &.Absent {
    color: #d98282;
  }
  &.Late {
    color: #ff9e0e;
  }
}
